<template>
  <div class="login-wrapper">
    <VerifyPhone ref="VerifyPhone"></VerifyPhone>
    <VerifyPhoneBind ref="VerifyPhoneBind"></VerifyPhoneBind>
    <!-- <VerifyEmail ref="VerifyEmail"></VerifyEmail> -->
    <VerifyEmailBind ref="VerifyEmailBind"></VerifyEmailBind>
    <VerifyPhoneBind2 ref="VerifyPhoneBind2"></VerifyPhoneBind2>
    <VerifyPhoneBind3 ref="VerifyPhoneBind3"></VerifyPhoneBind3>
    <ResetPassword ref="ResetPassword"></ResetPassword>
  </div>
</template>

<script>
import VerifyPhone from "./VerifyPhone.vue";
import VerifyPhoneBind from "./VerifyPhoneBind.vue";
// import VerifyEmail from "./VerifyEmail.vue";
import VerifyEmailBind from "./VerifyEmailBind.vue";
import VerifyPhoneBind2 from "./VerifyPhoneBind2.vue";
import VerifyPhoneBind3 from "./VerifyPhoneBind3.vue";
import ResetPassword from "./ResetPassword.vue";
export default {
  components: {
    VerifyPhone,
    VerifyPhoneBind,
    // VerifyEmail,
    VerifyEmailBind,
    VerifyPhoneBind2,
    VerifyPhoneBind3,
    ResetPassword,
  },
  data() {
    return {};
  },
  mounted() {
    this.$refs.VerifyPhone.dialogVisible = true;
    this.$refs.VerifyPhoneBind.dialogVisible = true;
    // this.$refs.VerifyEmail.dialogVisible = true;
    this.$refs.VerifyEmailBind.dialogVisible = true;
    this.$refs.VerifyPhoneBind2.dialogVisible = true;
    this.$refs.VerifyPhoneBind3.dialogVisible = true;
    this.$refs.ResetPassword.dialogVisible = true;
  },
  methods: {},
};
</script>

<style scoped="scoped">
.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../../assets/images/login/bg.jpg) center no-repeat;
  background-size: cover;
  height: 100vh;
}
</style>
