import { render, staticRenderFns } from "./VerifyPhoneBind2.vue?vue&type=template&id=105231f3&scoped=true"
var script = {}
import style0 from "./VerifyPhoneBind2.vue?vue&type=style&index=0&id=105231f3&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "105231f3",
  null
  
)

export default component.exports