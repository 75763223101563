<template>
	<el-dialog class="verVisible" :visible.sync="dialogVisible" width="400px" append-to-body :close-on-click-modal="false">
		<div class="ver-panel">
			<div class="ver-head">
				<div class="l-title">绑定/更换手机号</div>
				<div class="l-desc">

				</div>
			</div>
			<el-form :model="ruleForm" >
				<el-form-item prop="phone">
					<div class="input-select-group">
						<el-select v-model="ruleForm.select" slot="prepend" placeholder="请选择">
							<el-option label="中国+86" value="1"></el-option>
							<el-option label="中国+86" value="2"></el-option>
							<el-option label="中国+86" value="3"></el-option>
						</el-select>
						<el-input v-model="ruleForm.phone"  placeholder="请输入手机号">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item prop="code">
					<el-input v-model="ruleForm.code"  placeholder="请输入6位短信验证码">
						<span slot="suffix" class="btn btn-getcode">获取短信验证码</span>
					</el-input>
				</el-form-item>
				<el-form-item >
					<div class="ver-foot">
						<el-button class="btn-submit" type="primary" @click="submitForm('ruleForm')">验证</el-button>
						<el-button class="btn-submit" disabled type="primary" @click="submitForm('ruleForm')">验证</el-button>
					</div>
				</el-form-item>
			</el-form>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		components: {
		},
		data() {
			return {
				dialogVisible:false,
				ruleForm: {
					select:"1",
					phone: '',
					code: '',
				},
			};
		},
		mounted() {},
		methods: {
			submitForm() {
				
			},
		},
	};
</script>

<style scoped="scoped">
	.verVisible ::v-deep .el-dialog{
		border-radius: 10px;
	}
	.verVisible ::v-deep .el-dialog__body{
		padding: 10px 30px;
	}
	.ver-head{
		height: 80px;
		text-align: center;
		color: #333;
		.l-title{
			font-size: 16px;
		}
		.l-sub-title{
			font-size: 13px;
		}
		.l-desc{
			color: #999;
			margin-top: 10px;
			font-size: 12px;
		}
	}
	.btn-getcode{
		display: inline-flex;
		border: 0;
		color: #007DF7;
		padding: 8px 10px;
		cursor: pointer;
	}
	.ver-panel ::v-deep .el-input__inner{
		background: #F8F8F8;
		border-radius: 10px;
		border-color: #F8F8F8!important;
	}
	.ver-panel .btn-submit{
		width: 100%;
		margin: 0;
		border-radius: 10px;
	}
	.ver-panel .btn-submit:not(.is-disabled){
		background: linear-gradient(90deg, #007BF7 0%, #0AABFB 100%);
	}
	.flex_bd{
		flex:1;
		min-width: 0;
	}
	.input-select-group{
		position: relative;
	}
	.input-select-group .el-select{
		position: absolute;
		left: 0;
		top: 0;
		z-index: 119;
		width: 120px;
	}
	
	.input-select-group .el-input ::v-deep .el-input__inner{
		padding-left: 140px!important;
	}
	.ver-phone-cell{
		font-size: 14px;
	}
	
	
	
	
	
	
	
	
	
	
	
</style>